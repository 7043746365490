import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/HomeView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "home" */ '../views/privacy/PrivacyView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "home" */ '../views/contact/ContactView.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "home" */ '../views/legal/LegalView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
